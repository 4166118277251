import React from "react"
import { PageTitle } from "../parts/Title"
import { Values } from "./Values"

export function Sales() {
  return (
    <section
      className="text-blue-night font-body overflow-hidden"
      id="sales-page"
    >
      <div className="pt-14 mx-auto md:bg-top-pattern bg-no-repeat bg-contain bg-none">
        <div className="py-8 pb-5 md:bg-none bg-top-pattern bg-no-repeat bg-cover bg-center">
          <PageTitle>Tăng trưởng doanh số trong tầm tay bạn</PageTitle>
        </div>
        <Values />
      </div>
    </section>
  )
}

import { Link } from "gatsby"
import React from "react"

import { REACT_APP_SIGNUP_URL } from "../../constants"
import { CTA2 } from "../index/CTA"
import { ImageLeft, ImageRight } from "../parts/blocks"
import { SectionTitle } from "../parts/Title"

export function Values() {
  return (
    <>
      <RunMarketing />
      <CTA2 title="Công cụ marketing toàn diện cho sales BĐS" />
      <SaveTime />
    </>
  )
}

function RunMarketing() {
  const content = (
    <>
      <SectionTitle>Chủ động tiếp cận chính xác tập khách nét</SectionTitle>
      <p className="md:mb-8 leading-relaxed text-gray-normal text-left">
        <span className="font-bold text-blue-night">cokhach.vn</span> giúp bạn
        "đứng trên vai người khổng lồ" để "chạy số" như một nhà quảng cáo chuyên
        nghiệp. Luồng tạo & quản lý quảng cáo được thiết kế sẵn hỗ trợ bạn tìm
        đúng nguồn khách tiềm năng mà không mất nhiều thời gian học hiểu.
        <ul className="list-none list-inside pt-8 w-full text-left text-blue-night">
          <li>
            • 100+ mẫu landing page Bất động sản có tỷ lệ chuyển đổi tốt nhất.
          </li>
          <li>
            • Trình tạo quảng cáo tích hợp kho tri thức của top marketer trong
            ngành.
          </li>
          <li>• Trình tạo nội dung quảng cáo trúng đích, sáng tạo.</li>
          <li>• Gợi ý tối ưu quảng cáo thông minh kèm hướng dẫn chi tiết.</li>
        </ul>
      </p>
    </>
  )
  const image = (
    <img
      className="object-cover object-center rounded"
      alt="hero"
      src="https://cdn.cokhach.vn/pimage/home-page/right-image.png"
    />
  )
  return <ImageRight {...{ content, image }} />
}

function SaveTime() {
  const content = (
    <>
      <SectionTitle>
        Tập hợp tất cả tính năng bạn cần để tìm kiếm khách hàng
      </SectionTitle>

      <p className="md:mb-8 leading-relaxed text-gray-normal text-left">
        Thay vì đầu tư cho đội marketing truyền thống, hoặc kết hợp nhiều loại
        công cụ marketing,{" "}
        <span className="font-bold text-blue-night">cokhach.vn</span> là giải
        pháp marketing toàn diện tiết kiệm nguồn lực.
        <ul className="list-none list-inside pt-8 w-full text-left text-blue-night">
          <li>• Tìm kiếm khách hàng thông qua quảng cáo.</li>
          <li>• Quản lý thông tin & nuôi dưỡng khách hàng.</li>
          <li>• Các tính năng marketing tự động hoá.</li>
          <li>• Hỗ trợ hoạt động theo đội nhóm.</li>
        </ul>
      </p>
      <Link
        id="sales_singup"
        target="_blank"
        className="md:mt-0 mt-8 md:w-auto w-full font-medium text-white bg-pink-medium border-0 py-md px-5 focus:outline-none hover:bg-pink-500 rounded-md text-base"
        to={`${REACT_APP_SIGNUP_URL}`}
      >
        Dùng thử miễn phí
      </Link>
    </>
  )
  const image = (
    <img
      className="object-cover object-center rounded"
      alt="hero"
      src="https://cdn.cokhach.vn/pimage/home-page/left-image.png"
    />
  )
  return <ImageLeft {...{ content, image }} />
}

/*
function Automate() {
  const content = (
    <>
      <div className="">
        <h2>Công cụ marketing toàn diện cho sales BĐS</h2>
        <p></p>
      </div>
      <div className="">
        <input name="name" />
        <input name="email" />
        <button>Đăng ký</button>
      </div>
    </>
  )
  return content
}
*/

import React, { ReactElement } from 'react';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Pricing } from '../components/index/Pricing';
import { Meta } from '../components/Meta';
import { Sales } from '../components/sales/Sales';

interface Props { }

function Features(_props: Props): ReactElement {
  return (
    <>
      <Meta title="Hỗ trợ sales bất động sản tìm kiếm, nuôi dưỡng khách hàng | cokhach.vn"
        description="Tiếp cận chính xác tập khách nét, trở thành nhà quảng cáo chuyên nghiệp, không đòi hỏi kỹ năng. | Bắt đầu sử dụng miễn phí!" />
      <Header isBgTransparent={true} />
      <Sales />
      <Pricing />
      <Footer />
    </>
  )
}

export default Features
